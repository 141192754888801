import photoc_1 from './img/5f217367-f2c3-401e-a8c3-c446ea414283.jpg';
import photoc_2 from './img/7e659d65-c096-41ad-981b-c04d470261a9.jpg';
import photom from './img/marise.png';

function Marise() {
    return (
      <div className='outlet-content'>
        <h2>Gastouder Marise</h2>
        <div className='text-horizontal'>
            <img className='photo-style-m' src={photom} alt="" />
            <div className='text-block'>
                Graag stel ik mezelf aan jullie voor. Ik ben Marise Ottevanger, geboren in 1982. Ik ben getrouwd met Luuk en moeder van Tijs (2012) en Sara (2015). Met z'n vieren wonen we in Dordrecht in de wijk Dubbeldam. We wonen in een vrijstaand huis met een heerlijke tuin, waar onze 2 katten en 5 kippen zich ook goed vermaken.
                <br/>
                <br/>
                In 2007 ben ik afgestudeerd aan de PABO, waarbij ik ook een Montessori diploma heb gehaald. In de jaren daarna heb ik op verschillende scholen gewerkt, eigenlijk altijd in de kleuterklassen. Mijn hart ligt echt bij het jonge kind. 
                Toen in 2012 de oudste werd geboren besloten we bewust dat ik thuis zou blijven als 'thuisblijfmoeder'. De jaren thuis met de kinderen heb ik als erg fijn ervaren. Nu zijn ze al een stuk groter en wil ik graag in ons huis kleinschalige gastouderopvang aanbieden voor kinderen van 0 tot 4 jaar.
            </div>
        </div>
        <br/>
        
        <div className='photo-h'>
            <img className='photo-style-1' src={photoc_1} alt="" />
            <img className='photo-style-1' src={photoc_2} alt="" />

            {/* <img className='photo-style-1' src={photo1} alt="" />
            <img className='photo-style-1' src={photo2} alt="" />
            <img className='photo-style-1' src={photo3} alt="" />
            <img className='photo-style-1' src={photo4} alt="" /> */}
        </div>
      </div>
    );
  }
  
  export default Marise;
  