import photoc_1 from './img/f0046abc-5b01-4179-9826-9fc36607255d.jpg';
import photoc_2 from './img/596ce5ac-e34b-4bde-bb94-12c423a8d209.jpg';

function OpeningsTijden() {
    return (
      <div className='outlet-content'>
        
        <h2>Openingstijden</h2>

        <div>maandag 07.00 - 18.00 uur</div>
        <div>woensdag 07.00 - 18.00 uur</div>
        <div>donderdag 07.00 - 18.00 uur</div>
        <br />
        <div>Als gastouder vang ik maximaal 3 kindjes onder de 4 jaar op. </div>
        <br />
        <div  class='text-block'>
        Wij zorgen voor een gezonde lunch en tussendoortjes en voldoende drinken; water of thee. 
        Het is fijn als jullie zelf zorgen voor moedermelk/kunstvoeding, luiers, billendoekjes en eventueel andere voeding ivm specifieke wensen.
        </div>
        <br />
        <div className='photo-h'>
            <img className='photo-style-1' src={photoc_1} alt="" />
            <img className='photo-style-1' src={photoc_2} alt="" />
        </div>
        <br />

        <br />


      </div>
    );
  }
  
  export default OpeningsTijden;
  