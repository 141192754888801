import photoc_1 from './img/00c9b4f6-2d46-44f7-b5b9-4f7a34bf2c7b.jpg';
import photoc_2 from './img/31ed9dff-570d-4faa-945a-442a30cfa3e3.jpg';

function Opvang() {
    return (
      <div className='outlet-content'>
        <h2>De opvang</h2>

        <div className="text-block">Graag laat ik mij nog steeds inspireren door Maria Montessori. De speelmaterialen zijn in open kasten geplaatst en op ooghoogte voor de kinderen. Zo kunnen en mogen ze zelf pakken waar ze mee aan de slag willen. 'Help mij het zelf te doen' is een bekende uitspraak van Montessori. Kinderen observeren en bekijken hoe ver ze zelf komen met hun activiteiten vind ik altijd erg interessant. Waar nodig help ik natuurlijk of bied ik nog iets aanvullends aan. </div>
        <br />
        <div className="text-block">Mijn eigen kinderen zitten op de Vrije School en ik haal dan ook inspiratie uit de antroposofie. Leven met de seizoenen en in de natuur zijn is altijd fijn. We gaan dan ook dagelijks naar buiten, in onze eigen tuin is al genoeg te beleven. We gaan ook lopend met de kinderwagen of draagzak of -doek op avontuur in de wijk. Voor uitstapjes verder weg hebben we een bakfiets met elektrische trapondersteuning.  </div>
        <br />
        <div className='photo-h'>
            <img className='photo-style-1' src={photoc_1} alt="" />
            <img className='photo-style-1' src={photoc_2} alt="" />
        </div>

        <br />
        <div className="text-block">Gezonde voeding die zo min mogelijk bewerkt is en seizoensgebonden vind ik erg belangrijk. Zeker voor kleine kinderen die zo hard groeien en zich ontwikkelen is echt voedzaam eten onmisbaar. We eten gedeeltelijk biologisch en vegetarisch. Bij zoveel mogelijk eetmomenten wordt er iets van groente en/of fruit aangeboden.
            We hebben een moestuin waar zeker in de zomer van alles te oogsten is. Heerlijk zo uit de grond en ook nog eens zonder verpakkingsmateriaal. Onze kippen voorzien ons van lekkere verse eitjes. Regelmatig gaan we de keuken in om bijvoorbeeld brood te bakken, appelmoes te maken of soep te koken.
        </div>
        <br />
        <div className="text-block">
          Het goed zorgen voor onze aarde en de natuur vind ik iets dat kinderen niet vroeg genoeg kunnen leren. Veel speelgoed en boeken schaf ik tweedehands aan, er zijn zoveel mooie dingen te vinden die een tweede leven verdienen. Uiteraard wordt alles netjes schoon gemaakt en gecontroleerd voor het hier de kast in gaat. 
        </div>


        <h2>Dagindeling</h2>
        <div className="text-block">
        De kinderen kunnen 's ochtends vanaf 7 uur gebracht worden, wie vroeg is kan nog met ons mee ontbijten. Tot 9 uur wordt er vrij gespeeld en 
rond half 10 eten we fruit. Daarna worden de luiers verschoond en gaan de kinderen die dat al kunnen op het potje of het toilet. Dan gaan we met zijn allen naar buiten.
<br />Om 12 uur lunchen we en is er weer een verschoonmoment.
De kinderen die nog slapen in de middag gaan rond 13:00 uur naar bed. Als om 15:00 uur iedereen weer wakker is en verschoond, eten we een tussendoortje.
Daarna kan er weer vrij gespeeld worden of doen we een groepsactiviteit. 
Tussen 17:00 en 18:00 uur kunnen de kinderen weer opgehaald worden.
<br />
Dit is een overzicht van hoe een dag er ongeveer uit ziet. Uiteraard hou ik rekening met de behoeftes van de kinderen; soms zal er bijvoorbeeld langer of korter geslapen worden of zijn er kinderen die nog meerdere keren per dag slapen.
        </div>
        
        
      </div>
    );
  }
  
  export default Opvang;
  