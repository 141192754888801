import photoc_1 from './img/3a100ff9-914b-4ccf-a8e9-8e478bc73694.jpg';

function Contact() {
    return (
      <div className="outlet-content">
        <h2>Contact</h2>

        <div>Meer informatie over gastouderopvang Otje?</div>
        <div>Afspraak maken voor een vrijblijvend kennismakingsgesprek? </div>
        <div>Neem contact op!</div>

        <h3>Contactgegevens</h3>
        <div>Marise Ottevanger</div>
        <div>info@gastouderopvangotje.nl</div>
        <div>Landelijk Register Kinderopvang (LRK): 219814910</div>
        <br />
        <div className='photo-h'>
            <img className='photo-style-1' src={photoc_1} alt="" />
        </div>

      </div>
    );
  }
  
  export default Contact;
  