import './App.css';
import { Outlet, NavLink } from "react-router-dom";
import avatar from './img/logo_groot.png';

function App() {
  return (
    <div className="app">
      <header className='logo-container'>
        <img className='logo' src={avatar} />
      </header>
      <div className='menu-container'>
        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to={`/`}>Gastouder Marise</NavLink>  
        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to={`/opvang`}>De opvang</NavLink>  
        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to={`/openingstijden`}>Openingstijden</NavLink>  
        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to={`/contact`}>Contact</NavLink>  
      </div>
      <div className='outlet-container'>
        <div className='outlet'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
